module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"escape-the-app","short_name":"escape","start_url":"/","background_color":"#000000","theme_color":"#4dbac1","display":"minimal-ui","icon":"src/assets/images/escape-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"05c655b90c93c9a52099962782ce025f"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"antiblanks-escape-blog-cms","path":"/preview","previews":true,"pages":[{"type":"Post","match":"/blog/:uid","path":"/home","component":"/Users/danielivanovic/Development/antiblanks.escape.blog/src/components/post.js"},{"type":"Landing_header","match":"/:uid","path":"/home","component":"/Users/danielivanovic/Development/antiblanks.escape.blog/src/components/landing-page.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-169046670-1","head":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
